<template>
  <base-section id="theme-features">
    <base-section-heading title="¡Bienvenido!" />
    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            <div v-if="text">
              { text }
            </div>
            <div v-else>
              <base-btn
                href="https://drive.google.com/drive/folders/1iIO4F59OLPT7BxJ-x3Rg33dFkjEFyaUd?usp=sharing"
                target="_blank"
                title="ACTIVIDADES DE APOYO"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size: 3vw' : 'font-size: 1vw'"
              >
                VER
              </base-btn>
            </div>
          </base-avatar-card>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        style="margin-bottom: 15px;"
      >
        <div>
          <iframe
            src="https://ieindustrialtulua.edu.co/images_general/industrial_cumple.mp4"
            width="{530}"
            frameborder="{0}"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="height: 500px;"
          />
        </div>
      </v-row>
    </v-container>
    <br>
    <v-container>
      <base-section-heading title="Información importante" />
      <v-row justify="center">
        <div>
          <base-img
            class="mx-auto mb-6"
            :src="require('@/assets/Informacion1.jpeg')"
            width="500"
          />
        </div>
        <div>
          <base-img
            class="mx-auto mb-6"
            :src="require('@/assets/Informacion2.jpeg')"
            width="500"
          />
        </div>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          title: 'Matriculas Abiertas',
          icon: 'mdi-fountain-pen-tip',
          text: 'Fórmate como bachiller y a la vez prepárate para ser técnico en una de nuestras especialidades.',
          link: '',
        },
        {
          title: 'RENDICIÓN DE CUENTAS 2021-1',
          icon: 'mdi-help-circle-outline',
          text: '',
          link: 'https://drive.google.com/drive/folders/1iIO4F59OLPT7BxJ-x3Rg33dFkjEFyaUd?usp=sharing',
        },
      ],
    }),
  }
</script>
